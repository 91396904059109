import PropTypes from "prop-types";
import { useEffect, useReducer, useCallback, useMemo } from "react";
import { Amplify, Auth } from "aws-amplify";
// config
import { AwsConfig } from "src/config-global";
//
import { AuthContext } from "./auth-context";
import { getUserByEmail } from "src/libs/api";
import { fAWSDate } from 'src/utils/format-time'

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------
const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

// Auth.configure(AMPLIFY_API);
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AwsConfig.cognito.REGION,
    userPoolId: AwsConfig.cognito.USER_POOL_ID,
    identityPoolId: AwsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: AwsConfig.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: AwsConfig.s3.REGION,
    bucket: AwsConfig.s3.BUCKET,
  },
  API: {
    endpoints: AwsConfig.apiGateway.endpoints,
  },
});

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const params = {
        email: currentUser.attributes.email,
      };
      const dbUser = await getUserByEmail(params);

      if (currentUser) {
        dispatch({
          type: "INITIAL",
          payload: {
            user: {
              ...currentUser,
              id: currentUser.attributes.sub,
              userID: dbUser.SK,
              displayName: `${currentUser.attributes.given_name} ${currentUser.attributes.family_name}`,
              role: "patient",
            },
          },
        });
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const currentUser = await Auth.signIn(email, password);
    const params = {
      email,
    };
    const dbUser = await getUserByEmail(params);

    dispatch({
      type: "INITIAL",
      payload: {
        user: {
          ...currentUser,
          id: currentUser.attributes.sub,
          userID: dbUser.SK,
          loginStatus: dbUser.loginStatus,
          displayName: `${currentUser.attributes.given_name} ${currentUser.attributes.family_name}`,
          role: "patient",
        },
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(
    async (
      email,
      password,
      firstName,
      lastName,
      userDOB,
      // menstrualPeriod,
      // uterusRemoved,
      // ovariesRemoved,
    ) => {
      try {
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            given_name: firstName,
            family_name: lastName,
            "custom:userDOB": fAWSDate(userDOB),
            "custom:custom1": "newUser",
            // "custom:menstrualPeriod": menstrualPeriod,
            // "custom:uterusRemoved": uterusRemoved,
            // "custom:ovariesRemoved": ovariesRemoved,
          },
        });
      } catch (err) {
        return { statusCode: 400, data: err.message };
      }
    },
    [],
  );

  // CONFIRM REGISTER
  const confirmRegister = useCallback(async (email, code) => {
    try {
      const response = await Auth.confirmSignUp(email, code);
      return response;
    } catch (err) {
      if (err.code !== "NotAuthorizedException") {
        return err;
      }
    }
  }, []);

  // UPDATE USER
const updateUser = useCallback(async (firstName, lastName) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await Auth.updateUserAttributes(user, {
        given_name: firstName,
        family_name: lastName,
      });
	  return { statusCode: 200, data: null };
    }  catch (err) {
        return { statusCode: 400, data: err.message };
    }
  }, []);

  // RESEND CODE REGISTER
  const resendCodeRegister = useCallback(async (email) => {
    await Auth.resendSignUp(email);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await Auth.signOut();
    dispatch({
      type: "LOGOUT",
    });
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await Auth.forgotPassword(email);
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(async (email, code, password) => {
    await Auth.forgotPasswordSubmit(email, code, password);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";

  const status = state.loading ? "loading" : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: "amplify",
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      //
      login,
      logout,
      register,
      updateUser,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      updateUser,
      newPassword,
      forgotPassword,
      confirmRegister,
      resendCodeRegister,
    ],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
