import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// auth
import { AuthGuard } from "src/auth/guard";
// layouts
import DashboardLayout from "src/layouts/dashboard";
// components
import { LoadingScreen } from "src/components/loading-screen";

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import("src/pages/dashboard/chat"));

// // APP
const ChatPage = lazy(() => import("src/pages/dashboard/chat"));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const PermissionDeniedPage = lazy(() =>
  import("src/pages/dashboard/permission"),
);
// BLANK PAGE
const BlankPage = lazy(() => import("src/pages/dashboard/blank"));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: "chat", element: <ChatPage /> },
      {
        path: 'user',
        children: [
            { element: <UserAccountPage />, index: true },
            { path: 'account', element: <UserAccountPage /> },
        ],
      },
      { path: "permission", element: <PermissionDeniedPage /> },
      { path: "blank", element: <BlankPage /> },
    ],
  },
];
