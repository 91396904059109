import { API } from "aws-amplify";

const endpoint = {
  createUser: () => "/user",
  getUser: (id) => `/user/${id}`,
  getUserByEmail: (id) => "/user/email",
  getUsers: () => "/user/list",
  updateUserCustomer: (id) => `/user/${id}`,
  createConversation: () => "/conversation",
  getConversation: (id) => `/conversation/${id}`,
  deleteConversation: (id) => `/conversation/${id}`,
  createMessage: () => "/conversation/message",
  updateMessageDislike: () => `/conversation/message/dislike`,
  createCustomer: () => `/payment/customer`,
  deleteCustomer: (id) => `/payment/customer/${id}`,
  getCustomer: (id) => `/payment/customer/${id}`,
  createSubscription: () => `/payment/subscription`,
  getSubscription: (id) => `/payment/subscription/${id}`,
  updateSubscription: (id) => `/payment/subscription/${id}`,
  updateSubscriptionStatus: (id) => `/payment/subscription/status/${id}`,
  cancelSubscription: (id) => `/payment/subscription/cancel/${id}`,
  getInvoice: (id) => `/payment/invoice/${id}`,
  getInvoices: (id) => `/payment/invoice/customer/${id}`,
  getUpcomingInvoice: (id) => `/payment/invoice-upcoming/customer/${id}`,
  getInvoiceInfo: (id) => `/payment/invoiceinfo/customer/${id}`,
  createPaymentIntent: () => "/payment",
  getProduct: () => `/product`,
  getProductMain: () => `/product/main`,
};

/************
 * USER
 ************/
export const createUser = async (params) => {
	const response = await API.post("user", endpoint.createUser(), {
		body: params,
	});
  return response;
};

export const getUser = async (id) => {
	const response = await API.get("user", endpoint.getUser(id));
	return response;
};

export const updateUserCustomer = async (id, params) => {
	const response = await API.put("user", endpoint.updateUserCustomer(id), {
		body: params,
	});
  return response;
};

export const getUsers = async (params) => {
	const response = await API.get("user", endpoint.getUsers(), {
		queryStringParameters: params,
	});
  return response;
};

export const getUserByEmail = async (params) => {
	const response = await API.get("user", endpoint.getUserByEmail(), {
		queryStringParameters: params,
	});
  return response;
};

/****************
 * CONVERSATION
 ****************/
export const createConversation = async (params) => {
	const response = await API.post(
		"conversation",
		endpoint.createConversation(),
		{
		body: params,
		},
	);
  return response;
};
export const sendMessage = async (params) => {
	const response = await API.post("conversation", endpoint.createMessage(), {
		body: params,
	});
	return response;
};

export const getConversation = async (id, params) => {
	const response = await API.get("conversation", endpoint.getConversation(id), {
		queryStringParameters: params,
	});
	return response;
};

export const deleteConversation = async (id) => {
	const response = await API.del(
		"conversation",
		endpoint.deleteConversation(id),
	);
  return response;
};

export const updateMessageDislike = async (params) => {
	const response = await API.put("conversation", endpoint.updateMessageDislike(), {
		body: params,
	});
	return response;
};

/*******************
 * PAYMENT API
 ********************/

export const createCustomer = async (params) => {
	const response = await API.post("payment", endpoint.createCustomer(), {
		body: params,
	});
  return response;
};

export const deleteCustomer = async (params) => {
	const response = await API.post("payment", endpoint.deleteCustomer(), {
		body: params,
	});
  return response;
};


export const getCustomer = async (id) => {
	const response = await API.get("payment", endpoint.getCustomer(id));
	return response;
};

export const getInvoice = async (id) => {
	const response = await API.get("payment", endpoint.getInvoice(id));
	return response;
};

export const getInvoices = async (id) => {
	const response = await API.get("payment", endpoint.getInvoices(id));
	return response;
};

export const getInvoiceInfo = async (id, params) => {
	const response = await API.get("payment", endpoint.getInvoiceInfo(id), {
		queryStringParameters: params,
	});
	return response;
};

export const getUpcomingInvoice = async (id) => {
	const response = await API.get("payment", endpoint.getUpcomingInvoice(id));
	return response;
};

export const createSubscription = async (params) => {
	const response = await API.post("payment", endpoint.createSubscription(), {
		body: params,
	});
  return response;
};

export const getSubscription = async (id) => {
	const response = await API.get("payment", endpoint.getSubscription(id));
	return response;
};

export const cancelSubscription = async (id) => {
	const response = await API.post("payment", endpoint.cancelSubscription(id));
	return response;
};

export const updateSubscription = async (id, params) => {
	const response = await API.put("payment", endpoint.updateSubscription(id), {
		body: params,
	});
  return response;
};

export const updateSubscriptionStatus = async (id, params) => {
	const response = await API.put("payment", endpoint.updateSubscriptionStatus(id), {
		body: params,
	});
  return response;
};

export const createPaymentIntent = async (params) => {
	const response = await API.post("payment", endpoint.createPaymentIntent(), {
		body: params,
	});
  return response;
};

/*******************
 * PRODUCT API
 ********************/

export const getProduct = async () => {
	const response = await API.get("product", endpoint.getProduct());
	return response;
};
export const getProductMain = async () => {
	const response = await API.get("product", endpoint.getProductMain());
	return response;
};
