import { format, getTime, formatDistanceToNow, parseISO } from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm) : "";
}
export function fAWSDate(date, newFormat) {
  const fm = newFormat || "yyyy-MM-dd";

  return date ? format(new Date(date), fm) : "";
}

export function fDateMDY(date, newFormat) {
  const fm = newFormat || "PPP";

  return date ? format(new Date(parseISO(date)), fm) : "";
}

export function fDateCustom(date, newFormat) {
  const fm = newFormat || "MMM dd, yyyy";

  return date ? format(new Date(date), fm) : "";
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : "";
}
