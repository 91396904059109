// routes
import { paths } from "src/routes/paths";

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.chat; // as '/dashboard'

// Set API service names here
const apiNames = ["user", "conversation", "product", "payment"];

const createEndpoint = (name) => {
  let apiLink = "";

  switch (process.env.REACT_APP_STAGE) {
    case "dev":
      apiLink = process.env.REACT_APP_API_URL;
      break;
    case "staging":
      apiLink = process.env.REACT_APP_API_URL;
      break;
    case "prod":
      apiLink = process.env.REACT_APP_API_URL;
      break;
    default:
      apiLink = process.env.REACT_APP_API_URL;
      break;
  }
  const item = {
    name,
    endpoint: apiLink,
    region: process.env.REACT_APP_REGION,
  };
  return item;
};

const endpoints = [];
apiNames.forEach((item) => {
  const ep = createEndpoint(item);
  endpoints.push(ep);
});

export const AwsConfig = {
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  apiGateway: {
    endpoints,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
};
